<template>
  <div class="app-box">
    <x-scroll-view>
      <x-form-box>
        <x-form-input label="当前维护人" disable v-model="name"></x-form-input>
      </x-form-box>
      <i class="edit-icon" @click="editEvent"></i>
    </x-scroll-view>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import scrollView from "@/components/scrollView";

export default {
  data () {
    return {
      id: this.$route.params.id,
      name: this.$route.params.name
    };
  },
  created () {
    document.title = "";
    document.title = this.$route.params.orgName;
  },
  methods: {
    editEvent () {
      this.$router.push({
        path: `/customerAssignment/batchMaintain/${this.id}/${this.name}/2`
      });
    }
  },
  components: {
    [formBox.name]: formBox,
    [formInput.name]: formInput,
    [scrollView.name]: scrollView
  }
};
</script>

<style scoped></style>
